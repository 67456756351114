import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Moment, parseZone } from 'moment/moment';

import { TDateFieldType } from '@core/enums/date/date-field-type.enum';
import { Plan } from '@core/interfaces/plan/plan.interface';
import { IIdentityString } from '@core/interfaces/util/identity.interface';
import { TCommonFieldSize } from '@shared/components/form-field/common-field-size.enum';

@Component({
  selector: 'app-consumer-product-incident-form',
  templateUrl: './consumer-product-incident-form.component.html',
  styleUrls: ['./consumer-product-incident-form.component.scss'],
})
export class ConsumerProductIncidentFormComponent {

  @Input() incidentFormControl: UntypedFormGroup;
  @Input() selectedProblemCategory: IIdentityString;
  @Input() selectedProblemSubCategory: IIdentityString;

  @Output() problemCategorySelected = new EventEmitter<IIdentityString>();
  @Output() problemSubCategorySelected = new EventEmitter<IIdentityString>();

  dateNoticedMinDate: Date | Moment;
  TDateFieldType = TDateFieldType;
  today = new Date();
  protected readonly TCommonFieldSize = TCommonFieldSize;

  @Input() set planInfo(planInfo: Plan) {
    if (planInfo) {
      this.dateNoticedMinDate = parseZone(planInfo.deliveryDate).toDate();
    }
  }

  @Input() set planDeliveryDate(planDeliveryDate: string) {
    if (planDeliveryDate) {
      this.dateNoticedMinDate = parseZone(planDeliveryDate).toDate();
    }
  }

  @Input() set deliveryDate(deliveryDate: Date | Moment) {
    if (deliveryDate) {
      this.dateNoticedMinDate = deliveryDate;
    }
  }

  selectProblemCategory(problemCategory: IIdentityString): void {
    this.problemCategorySelected.emit(problemCategory);
  }

  selectProblemSubCategory(problemSubCategory: IIdentityString): void {
    this.problemSubCategorySelected.emit(problemSubCategory);
  }
}
