import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { BaseFormControlElement } from '@core/base/base-form-control-element';
import { IIdentityString } from '@core/interfaces/util/identity.interface';
import {
  ProblemTypeSubCategoryStore,
} from '@shared/modules/claim-controls/components/problem-type-sub-category-select/store/problem-type-sub-category.store';

interface IProblemTypeSubCategorySelectState {
  disabled: boolean;
}

@Component({
  selector: 'app-problem-type-sub-category-select',
  templateUrl: './problem-type-sub-category-select.component.html',
  styleUrls: ['./problem-type-sub-category-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProblemTypeSubCategoryStore, RxState],
})
export class ProblemTypeSubCategorySelectComponent extends BaseFormControlElement implements OnInit {
  @Input() label = 'Problem Type Subcategory';
  @Input() selectedProblemSubCategory: IIdentityString;

  problemTypeSubCategories$ = this.store.select$('items');
  loading$ = this.store.select$('loading');
  pagination$ = this.store.select$('pagination');
  disabled$ = this.state.select('disabled');

  @Input() set problemType(parentId: string) {
    if (this.formControl) {
      this.formControl.setValue(null);
    }

    if (parentId) {
      this.store.updateSearchRequest({
        parentId,
        page: 1,
      });
      this.store.loadData();
    }
  }

  @Output() categorySelected = new EventEmitter<IIdentityString>();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    protected readonly store: ProblemTypeSubCategoryStore,
    protected readonly state: RxState<IProblemTypeSubCategorySelectState>,
  ) {
    super(ngControl);
    this._initState();
  }

  problemTypeCategorySearch(searchString: string): void {
    this.store.search(searchString);
  }

  problemTypeSubCategorySelected(problemTypeSubCategory: IIdentityString): void {
    this.categorySelected.emit(problemTypeSubCategory);
  }

  loadNextPage(page: number): void {
    this.store.updatePagination(page);
  }

  _initState(): void {
    this.state.set({
      disabled: true,
    });

    this.state.connect(
      'disabled',
      combineLatest([this.store.select$('loading'), this.store.select$('items')]).pipe(
        filter(() => !!this.formControl),
        map(([loading, items]) => {
          const noItems = !items || items.length === 0;

          if (loading) {
            this.formControl.setValidators(Validators.required);
            this.formControl.updateValueAndValidity();
            return true;
          } else if (noItems) {
            this.formControl.setValidators([]);
            this.formControl.updateValueAndValidity();
            return true;
          } else {
            this.formControl.setValidators(Validators.required);
            this.formControl.updateValueAndValidity();
            return false;
          }
        }),
      ),
    );

    this.disabled$
      .pipe(
        takeUntil(this.unsubscribeOnDestroy$),
      )
      .subscribe((disabled) => {
        if (!this.formControl) {
          return;
        }
        if (disabled) {
          this.formControl.disable();
        } else {
          this.formControl.enable();
        }
      });
  }
}
