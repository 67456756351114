import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LetModule } from '@rx-angular/template/let';
import { PushModule } from '@rx-angular/template/push';
import { BehaviorSubject } from 'rxjs';

import { IIdentityString } from '@core/interfaces/util/identity.interface';
import {
  CONST_existedDefaultConfig,
  CONST_fromScratchDefaultConfig,
  ISelectProductConfig,
} from '@shared/modules/claim-dialogs/components/select-product/select-product.component';
import { SelectProductDialogComponent } from
  '@shared/modules/claim-dialogs/components/select-product-dialog/select-product-dialog.component';
import { ClaimFormModule } from '@shared/modules/claim-form/claim-form.module';

import { RegisterFppClaimStore } from '../../store/register-fpp-claim.store';

@Component({
  selector: 'app-fpp-claim-products',
  templateUrl: './fpp-claim-product.component.html',
  styleUrls: ['./fpp-claim-product.component.scss'],
  standalone: true,
  imports: [ClaimFormModule, MatButtonModule, PushModule, MatIconModule, NgIf, LetModule, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FppClaimProductComponent implements OnInit {
  form: FormGroup;
  activeProductIndex$ = this.store.select$('activeProductIndex');
  planDeliveryDate$ = this.store.select$('planDetails', 'planInfo', 'deliveryDate');
  rerender$ = new BehaviorSubject(true);
  problemCategorySelected$ = this.store.select$('problemCategorySelected');
  problemSubCategorySelected$ = this.store.select$('problemSubCategorySelected');

  constructor(private readonly store: RegisterFppClaimStore, private readonly matDialog: MatDialog) {}

  ngOnInit(): void {
    this.store.select$('activeProductIndex').subscribe(activeProductIndex => {
      const productsForm = this.store.getForm('coveredProducts');
      this.form = productsForm.controls[activeProductIndex] as FormGroup;
      // TODO: Temporary fix
      this.rerender$.next(false);
      setTimeout(() => {
        this.rerender$.next(true);
      }, 0);
    });
  }

  goBack(): void {
    const activeProductIndex = this.store.get('activeProductIndex');
    if (activeProductIndex === 0) {
      this.store.wizardNavigationStore.setStep('consumer');
    } else {
      this.store.goToProduct(activeProductIndex - 1);
    }
  }

  removeProduct(): void {
    const activeProductIndex = this.store.get('activeProductIndex');
    this.store.removeProduct(activeProductIndex);
    this.goBack();
  }

  goNext(): void {
    const activeProductIndex = this.store.get('activeProductIndex');
    const coveredProductsLength = this.store.getForm('coveredProducts').value.length;
    if (activeProductIndex < coveredProductsLength - 1) {
      this.store.goToProduct(activeProductIndex + 1);
    } else {
      this.store.wizardNavigationStore.setStep('files');
    }
  }

  addAnotherProduct(): void {
    const isPaperPlan = this.store.get('isPaperPlan');
    if (isPaperPlan) {
      this.store.addProducts(null);
      const activeProductIndex = this.store.get('activeProductIndex');
      this.store.goToProduct(activeProductIndex + 1);
    } else {
      const planProducts = this.store.get('planDetails', 'coveredProducts');
      const claimForm = this.store.getForm();
      const config: ISelectProductConfig = {
        fromScratch: CONST_fromScratchDefaultConfig,
        existed: CONST_existedDefaultConfig,
        displayButtonAddNewProductsToClaim: true,
      };
      const dialogRef = this.matDialog.open(SelectProductDialogComponent, {
        autoFocus: false,
        data: {
          products: planProducts,
          selectedProductIds: claimForm.get('coveredProducts').value.map(product => product.id),
          config,
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (!result) {
          return;
        }

        if (result.newProduct) {
          this.store.addProducts(null);
        } else {
          this.store.addProducts(result.selectedProducts);
        }

        const activeProductIndex = this.store.get('activeProductIndex');
        this.store.goToProduct(activeProductIndex + 1);
      });
    }
  }

  setSelectProblemCategory(problemCategory: IIdentityString): void {
    this.store.setSelectProblemCategory(problemCategory);
  }

  setSelectProblemSubCategory(problemSubCategory: IIdentityString): void {
    this.store.setSelectProblemSubCategory(problemSubCategory);
  }
}
