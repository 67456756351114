<div *rxLet="vm$; let vm">
  <ng-container *formGroup="vm.productForm">
    <div class="fpp-claim-product__header">
      <p class="fpp-claim-product__title">{{ vm.productName }}</p>
      <button
        type="button"
        mat-stroked-button
        class="link-button"
        (click)="deleteProduct()">
        Remove
      </button>
    </div>
    <ng-container>
      <app-form-textarea-new
        [formControlName]="FieldsNames.ProductDescription"
        [required]="true"
        [label]="'Describe the Product'"
        [expandHint]="true"
        [hint]="
          'What is the piece of furniture you are making a claim on? E.g. “fabric sofa”, “dining table”, “leather recliner with power”.'
        "></app-form-textarea-new>
      <app-surface-type-select-new
        [required]="true"
        [formControlName]="FieldsNames.SurfaceTypeId"
        (selectedItem)="selectedSurfaceType($event)"></app-surface-type-select-new>
      <app-form-date-new
        [label]="'Delivery Date'"
        [formControlName]="FieldsNames.DeliveryDate"
        [touchUi]="true"
        [autocomplete]="'section-purchase delivery-date'"
        [required]="true"
        [readonly]="true"
        [expandHint]="true"
        [hint]="
          'If you received your furniture in multiple deliveries, please put the earliest delivery date.'
        "
        [max]="today"></app-form-date-new>
      <app-form-input-new
        [formControlName]="FieldsNames.UnitPrice"
        [label]="'Unit Price'"
        [autocomplete]="'section-product unit-price'"
        [dataType]="'price'"
        [expandHint]="true"
        [hint]="
          'The price of the furniture as shown on your receipt. If the piece does not have a price as it was part of a package, please flip the Part of Package indicator to on and put the full package price in this field.'
        "
        [required]="true"></app-form-input-new>
      <div class="fpp-claim-product__switch-wrapper">
        <mat-slide-toggle
          [formControlName]="FieldsNames.IsPartOfPackage"
          class="fpp-claim-product__switch">
          Part of Package
        </mat-slide-toggle>
        <p class="fpp-claim-product__switch-hint">
          If your receipt shows multiple pieces of furniture as part of a package, for example 'living room
          package', with a single package price then please flip the toggle
        </p>
      </div>
    </ng-container>
    <div formGroupName="productIncident">
      <app-form-textarea-new
        formControlName="damageDescription"
        [required]="true"
        [expandHint]="true"
        [hint]="
          'Describe the problem, e.g. “coffee stain on the arm of the sofa”, “I spilled nail polish remover on my dining table”, “the power mechanism stopped working on my recliner”.'
        "
        [label]="'Describe the Problem'"></app-form-textarea-new>
      <div class="fpp-claim-product__warning-wrapper">
        <app-alert-message
          [fullSize]="true"
          [type]="'warning'"
          [message]="
            'The following drop-down fields are extremely important for adjudicating your claim. Please make sure they are accurate to the best of your ability.'
          "></app-alert-message>
      </div>
      <app-cause-select-new
        [label]="'How Did it Happen'"
        formControlName="causeTypeId"
        [required]="true"
        (selectedItem)="selectedCauseType($event)"></app-cause-select-new>
      <app-problem-type-select-new
        [label]="'Reported Damage'"
        formControlName="problemTypeId"
        [selectedProblemType]="problemCategorySelected$ | async"
        [required]="true"
        (problemTypeSelected)="selectedProblemType($event)"></app-problem-type-select-new>
      <app-problem-type-sub-category-select-new
        [label]="'Reported Damage Subcategory'"
        formControlName="problemTypeSubcategoryId"
        [selectedProblemSubCategory]="problemSubCategorySelected$ | async"
        (categorySelected)="selectedSubProblemType($event)"
        [problemType]="
          vm.productForm.get('productIncident.problemTypeId').value
        "></app-problem-type-sub-category-select-new>
      <app-form-date-new
        *appReadonlyControl="
          vm.productForm.get('productIncident.dateNoticed').enabled;
          else labelValueItem;
          label: 'Date Noticed';
          value: vm.productForm.get('productIncident.dateNoticed').value;
          let label = label
        "
        formControlName="dateNoticed"
        [touchUi]="true"
        [label]="label"
        [autocomplete]="'section-purchase purchase-date'"
        [required]="true"
        [readonly]="true"
        [expandHint]="true"
        [hint]="
          'When did you first notice the problem? (It can be helpful to establish the date by thinking about other events on the day you first noticed it, e.g. “it was the day we went to pick out new carpet”, “it was the day your friend from work came over for dinner”, “it was the night we watched that scary movie”.)'
        "
        [max]="today"
        [min]="vm.productForm.get('deliveryDate').value"></app-form-date-new>
    </div>
  </ng-container>
</div>
<ng-container *cdkPortal>
  <div
    class="fpp-claim-product__footer"
    *rxLet="disableContinueBtn$; let disableContinueBtn">
    <button
      mat-flat-button
      [class._common-button_submit-disabled]="disableContinueBtn"
      (click)="nextProduct(disableContinueBtn)">
      Continue
    </button>
  </div>
</ng-container>
<ng-template
  #labelValueItem
  let-label="label"
  let-value="value">
  <app-label-value [label]="label">
    <p>{{ value | dateCustomFormat: "MM/DD/YYYY" | appEmptyValue }}</p>
  </app-label-value>
</ng-template>
