<app-form-select-new
  [formControl]="formControl"
  [data]="problemTypes$ | push"
  [loading]="loading$ | push"
  [pagination]="pagination$ | push"
  [label]="label"
  [required]="required"
  [showSearch]="true"
  [loadOnScroll]="true"
  [selectedItem]="selectedProblemType"
  (selectSearch)="problemTypeSearch($event)"
  (itemSelected)="typeSelected($event)"
  (selectLoadNextPage)="loadNextPage($event)"></app-form-select-new>
